<template>
  <div
      v-loading="ShowLoading"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 0.3)"
      class="login flex-center">
    <el-form :model="ruleForm2" :rules="rules2" ref="ruleForm2" label-position="left" label-width="0px" class="demo-ruleForm login-container">
      <div class="title">装饰幕墙机械设备管理系统</div>
      <el-form-item prop="account">
        <el-input type="text" v-model="ruleForm2.account" auto-complete="off" placeholder="账号"></el-input>
      </el-form-item>
      <el-form-item prop="checkPass">
        <el-input type="password" v-model="ruleForm2.checkPass" auto-complete="off" placeholder="密码"></el-input>
      </el-form-item>
      <!-- <el-checkbox v-model="checked" checked class="remember">记住密码</el-checkbox> -->
      <el-form-item style="width:100%;">
        <el-button type="primary" style="width:100%;" @click.native.prevent="handleSubmit2">登录</el-button>
        <!--<el-button @click.native.prevent="handleReset2">重置</el-button>-->
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  import apis from './apis'
  import { accessToken } from "../../utils/local-cache"
  export default {

    name: 'login',

    components: {
    },

    props: {
    },

    data() {
      return {
        ruleForm2: {
          account: '',
          checkPass: ''
        },
        rules2: {
          account: [
            { required: true, message: '请输入账号', trigger: 'blur' },
          ],
          checkPass: [
            { required: true, message: '请输入密码', trigger: 'blur' },
          ]
        },
        checked: true,
        ShowLoading: false
      }
    },

    computed: {
    },

    watch: {
    },

    // mounted() {
      // this.init()
    // },

    methods: {
       login() {
         apis.login(this.ruleForm2).then(res=>{
           accessToken.set(res)
           this.getUserInfo()
         }).catch(() => {
           this.ShowLoading = false
         })
        // const token = await
        // console.log(token)
        // accessToken.set(token)
        // this.getUserInfo()
      },
      handleSubmit2() {
        this.$refs.ruleForm2.validate((valid) => {
          if (valid) {
            // _this.$router.replace('/table');
            this.ShowLoading = true
            // NProgress.start();
            this.login()
          } else {
            console.log('error submit!!')
            return false
          }
        })
      },
      async getUserInfo() {

        try {
          const info = await apis.getUserInfo()
          this.ShowLoading = false
          if (info.dtoList.length == 0) {
            accessToken.set('')
            return this.$message.error('请联系管理员添加组织机构')
          }
          this.$store.dispatch('settings/getUserInfo', info)
          this.$store.dispatch('settings/changeOptionItem', info.dtoList[0])
          this.$store.dispatch('settings/changeCompanyId', info.dtoList[0].departmentCode)
          this.$router.push({ name: 'Home' })
        } catch (error) {
          console.log(error)
          this.ShowLoading = false
        }

      }
    }
  }
</script>

<style lang="stylus" scoped>
  .login
    width 100%
    height 100%
    background url('../../assets/images/backgd.png') no-repeat
    background-size 100% 100%
    .login-container
      /*box-shadow: 0 0px 8px 0 rgba(0, 0, 0, 0.06), 0 1px 0px 0 rgba(0, 0, 0, 0.02);*/
      -webkit-border-radius: 5px;
      border-radius: 5px;
      -moz-border-radius: 5px;
      background-clip: padding-box;
      width: 400px;
      padding: 35px 35px 15px 35px;
      background: #fff;
      border: 0.1rem solid #eaeaea;
      box-shadow: 0 0 25px #cac6c6;
      .title
        margin: 0px auto 40px auto;
        text-align: center
        color: #505458
        font-size 20px
        letter-spacing 3px
      .remember
        margin: 0px 0px 35px 0px
</style>
