import fetch from '../../apis/request'

export default class Apis {
  // 登录
  static login(form) {
    return fetch.post(`/pc/user/login?username=${form.account}&password=${form.checkPass}`)
  }
  // 获得用户信息
  static getUserInfo() {
    return fetch.get('/pc/user/getLoginUserInfo')
  }
}
